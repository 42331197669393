import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { Pagination } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Opentrade = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [userId, setUserId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [currencyData, setCurrencyData] = useState([]);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [email, setEmail] = useState("");
  const [sponserId, SetSponserId] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [leverage, setLeverage] = useState("");
  const [wsConnections, setWsConnections] = useState({});
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    email: "",
    name: "",
    phonenumber: "",
    sponserId: "",
    currency: "",
    leverage: "",
  });
  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      email,
      currency,
      leverage,
      phonenumber,
      name,
      sponserId,
    });
  };
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setEmail("");
    setName("");
    setPhonenumber("");
    SetSponserId("");
    setCurrency("");
    setLeverage("");
    setFilters({
      startDate: "",
      endDate: "",
      userId: "",
      name: "",
      email: "",
      phonenumber: "",
      sponserId: "",
      currency: "",
      leverage: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "FUTURE",
        closeStatus: "false",
        from: filters.startDate,
        to: filters.endDate,
        uid: filters.userId,
        currency: filters.currency,
        leverage: parseInt(filters.leverage) || undefined,
        name: filters.name,
      };

      let response = await _fetch(
        `${base_url}api/trader/trades`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
        if (response.data && Array.isArray(response.data)) {
          setupWebSockets(response.data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const fetchCurrencyData = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/tokens`, "get");
      if (response.success === true) {
        setCurrencyData(response.data);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const setupWebSockets = (trades) => {
    const newConnections = {};
    trades.forEach((trade) => {
      const symbol = trade.symbol.toLowerCase();
      const ws = new WebSocket(`wss://fstream.binance.com/ws/${symbol}@ticker`);

      ws.onmessage = (event) => {
        const tickerData = JSON.parse(event.data);
        const currentPrice = parseFloat(tickerData.c);

        setData((prevState) => {
          if (!prevState?.data || !Array.isArray(prevState.data)) {
            return prevState;
          }

          return {
            ...prevState,
            data: prevState.data.map((item) => {
              if (item._id === trade._id) {
                const startPrice = parseFloat(item.startPrice);
                let profitLoss = 0;

                if (item.side === "BUY") {
                  profitLoss = currentPrice - startPrice;
                } else if (item.side === "SELL") {
                  profitLoss = startPrice - currentPrice;
                }

                return { ...item, profitLoss: profitLoss.toFixed(4) };
              }
              return item;
            }),
          };
        });
      };

      ws.onerror = (error) => console.error("WebSocket Error:", error);
      newConnections[trade.symbol] = ws;
    });

    setWsConnections((prev) => ({ ...prev, ...newConnections }));
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage, filters]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  useEffect(() => {
    return () => {
      Object.values(wsConnections).forEach((ws) => ws.close());
    };
  }, [wsConnections]);

  useEffect(() => {
    fetchCurrencyData();
  }, []);

  const handleClose = async (batchId, orderId) => {
    const confirmClose = window.confirm(
      "Are you sure you want to close this order?"
    );
    if (!confirmClose) return;
    setLoading(true);
    try {
      const requestData = {
        batchId,
        orderId,
      };

      let response = await _fetch(
        `${base_url}api/trader/closeForSingle`,
        "post",
        requestData
      );

      if (response.success === true) {
        toast.dismiss();
        toast.success(response.message);
        handledashboard();
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/viewopentrade?id=${userId}`;
        break;

      default:
        return;
    }

    navigate(url);
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return (
        <table className="table table-striped table-bordered mt-3">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Name</th>
              <th>User Id</th>
              <th>OrderId</th>
              <th>Symbol</th>
              <th>Status</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Type</th>
              <th>Side</th>
              <th>End Price</th>
              <th>Start Price</th>
              <th>Batch Id</th>
              <th>Binance Balance</th>
              {/* <th>Exchange Name</th> */}
              <th>Profit/Loss</th>
              <th>Leverage</th>
              <th>Date</th>
              <th>Close Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((row, i) => {
              const serialNumber = pageSer + i + 1;
              return (
                <tr key={i}>
                  <td>{serialNumber}</td>
                  <td>{row?.userId?.name}</td>
                  <td>{row?.userId?.userId}</td>
                  <td>{row?.orderId}</td>
                  <td>{row?.symbol}</td>
                  <td className="text-success">{row?.status}</td>
                  <td>{row?.price}</td>
                  <td>{row?.quantity}</td>
                  <td>{row?.type}</td>
                  <td>{row?.side}</td>
                  <td className="text-danger">{row?.endPrice}</td>
                  <td>{row?.startPrice}</td>
                  <td className="text-secondary">{row?.batchId}</td>
                  {/* <td>{row?.exchangeName}</td> */}
                  <td>{row?.walletBalance ? row?.walletBalance : "-"}</td>
                  <td
                    className={
                      row?.profitLoss < 0 ? "text-danger" : "text-success"
                    }
                  >
                    {row?.profitLoss}
                  </td>
                  <td>{row?.leverage}</td>
                  <td>
                    {dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}
                  </td>
                  <td>
                    {row?.closeStatus === true ? (
                      <span style={{ color: "red" }}>Closed</span>
                    ) : (
                      <span style={{ color: "green" }}>Open</span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-danger"
                        type="button"
                        disabled={loading}
                        onClick={() => handleClose(row.batchId, row._id)}
                      >
                        Close
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleStatusChange(row._id, "openview")}
                      >
                        View
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return <div>Data Not Found</div>;
    }
  };

  const getExportData = async () => {
    try {
      const exportRequest = {
        limit: 1000,
        skip: 0,
        type: "FUTURE",
        closeStatus: "false",
        from: filters.startDate,
        to: filters.endDate,
        name: filters.name,
        uid: filters.userId,
        currency: filters.currency,
        leverage: parseInt(filters.leverage),
        export: true,
      };

      const response = await _fetch(
        `${base_url}api/trader/trades`,
        "post",
        exportRequest
      );

      if (!response.success) {
        throw new Error(response.message || "Failed to fetch export data");
      }

      return response.data;
    } catch (error) {
      console.error("Export data fetch error:", error);
      toast.dismiss();
      toast.error("Failed to get export data");
      throw error;
    }
  };

  const exportToPDF = async () => {
    try {
      const data = await getExportData();

      const doc = new jsPDF();

      const tableColumns = [
        "S No.",
        "UserId",
        "OrderId",
        "Name",
        "Email",
        "Phone",
        "Symbol",
        "Status",
        "Price",
        "Quantity",
        "Type",
        "Side",
        "End Price",
        "Start Price",
        "Batch ID",
        "Binance Balance",
        "Profit/Loss",
        "Leverage",
        "Date",
        "Close Status",
      ];

      const tableRows = data.map((item, index) => [
        index + 1,
        item.userId,
        item.orderId,
        item.name,
        item.email,
        item.phonenumber,
        item.symbol,
        item.status,
        item.price,
        item.quantity,
        item.type,
        item.side,
        item.endPrice,
        item.startPrice,
        item.batchId,
        item.walletBalance || "-",
        item.profitLoss,
        item.leverage,
        dateFormat(item.createdAt, "yyyy-mm-dd"),
        item.closeStatus === true ? "Closed" : "Open",
      ]);

      doc.text("Open Trade Report", 14, 15);

      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 20,
        theme: "grid",
        styles: { fontSize: 8 },
      });

      doc.save("open-trade.pdf");
      toast.dismiss();
      toast.success("PDF downloaded successfully");
    } catch (error) {
      console.error("PDF export error:", error);
      toast.dismiss();
      toast.error("Failed to export PDF");
    }
  };

  const exportToExcel = async () => {
    try {
      const data = await getExportData();

      const excelData = data.map((item, index) => ({
        "S No.": index + 1,
        "User ID": item.userId,
        "Order ID": item.orderId,
        Name: item.name,
        Email: item.email,
        Phone: item.phonenumber,
        Symbol: item.symbol,
        Status: item.status,
        Price: item.price,
        Quantity: item.quantity,
        Type: item.type,
        Side: item.side,
        "End Price": item.endPrice,
        "Start Price": item.startPrice,
        "Batch ID": item.batchId,
        "Binance Balance": item.walletBalance || "-",
        "Profit/Loss": item.profitLoss,
        Leverage: item.leverage,
        Date: dateFormat(item.createdAt, "yyyy-mm-dd"),
        "Close Status": item.closeStatus === true ? "Closed" : "Open",
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Open Trade");

      XLSX.writeFile(workbook, "open-trade.xlsx");
      toast.dismiss();
      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Excel export error:", error);
      toast.dismiss();
      toast.error("Failed to export Excel");
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        <h2 className="text-center mb-3 text-primary">Open Trade</h2>

        {/* <div className=""> */}
        <div className="container container-trade2 mt-2 p-1">
          <div className="text-right1 p-3">
            <button className="btn btn-primary me-2" onClick={exportToPDF}>
              Export PDF
            </button>
            <button className="btn btn-primary" onClick={exportToExcel}>
              Export Excel
            </button>
          </div>
          <div className="card back-card">
            <div className="main-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>UserId</option>
                                                <option value="1">Email</option>
                                                <option value="2">Phone</option>
                                                <option value="3">sponserId</option>
                                            </select>
                                        </div> */}
                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="email"
                      placeholder="email Search"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div> */}

                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="User Id Search"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="Name Search"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="number"
                      placeholder="phone number Search"
                      value={phonenumber}
                      onChange={(e) => setPhonenumber(e.target.value)}
                    />
                  </div>
                </div> */}

                {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="sponser Id Search"
                      value={sponserId}
                      onChange={(e) => SetSponserId(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCurrency(e.target.value)}
                    value={currency}
                  >
                    {console.log(currencyData)}
                    <option value={""}>Select Currency</option>
                    {currencyData && currencyData.length > 0
                      ? currencyData.map((currency, index) => (
                        <option key={index} value={currency.token}>
                          {currency.token}
                        </option>
                      ))
                      : ""}
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="number"
                      placeholder="Leverage"
                      value={leverage}
                      onChange={(e) => setLeverage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                  <div className="reset-btn">
                    <button className="apply-btn" onClick={applyFilters}>
                      Apply Filters
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                  <div className="place-2">
                    <button
                      onClick={resetFilters}
                      className="btn btn-secondary"
                    >
                      Reset Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 className="text-right1 p-3 text-primary">
            Total open trades: {data?.count}
          </h4>
        </div>
        <div className="container container-trade2 mt-2 p-1">
          <div className="transactions">
            {records()}

            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Opentrade;
