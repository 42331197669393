import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import { Pagination } from "antd";

const Grouptrade = () => {
  const [uid, setUid] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [symbol, setSymbol] = useState("");
  const [mode, setMode] = useState("");
  const [timeex, setTimeex] = useState("GTC");
  const [type, setType] = useState("");
  const [margin, setMargin] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [leverage, setLeverage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [count, setCount] = useState('');
  const [latestPrice, setLatestPrice] = useState(null);
  const wsRef = useRef(null);



  useEffect(() => {
    // Cleanup WebSocket connection on unmount or symbol change
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (symbol) {
      if (wsRef.current) {
        wsRef.current.close(); // Close existing WebSocket connection
      }

      const url = `wss://fstream.binance.com/ws/${symbol.toLowerCase()}@ticker`;
      const ws = new WebSocket(url);

      ws.onopen = () => {
        console.log(`Connected to WebSocket for symbol: ${symbol}`);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data && data.c) {
          setLatestPrice(data.c); // Update latest price
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        console.log(`WebSocket for symbol: ${symbol} closed`);
      };

      wsRef.current = ws; // Save WebSocket instance
    } else {
      setLatestPrice(null); // Reset latest price if no symbol
    }
  }, [symbol]);

  console.log('count', count);

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "activeUsers",
      };
      let response = await _fetch(
        `${base_url}api/trader/members`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response.data); // Set fetched data
        setFilteredData(response.data);
        setCount(response)
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const handleCheckboxChange = (userId, isChecked) => {
    if (isChecked) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  // Function to filter data based on search input
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toUpperCase();
    setSearchType(searchTerm);

    if (searchTerm === "") {
      setFilteredData(data || []); // Reset filtered data if search is cleared
    } else {
      const filtered = (data || []).filter((item) =>
        item.userId.toUpperCase().includes(searchTerm)
      );
      setFilteredData(filtered); // Update filtered data based on search term
    }
  };

  const handledashboards = async (e) => {
    e.preventDefault();

    if (
      !symbol ||
      !mode ||
      !timeex ||
      !type ||
      !quantity ||
      !leverage
    ) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);
    try {
      const requestData = {
        userId: selectedUsers,
        symbol: symbol,
        mode: mode,
        timeInForce: timeex,
        type: type,
        quantity: parseInt(quantity),
        price: parseFloat(price),
        leverage: parseInt(leverage),
        marginType: margin,

      };

      let response = await _fetch(
        `${base_url}api/trader/placeForSingle`,
        "post",
        requestData
      );

      if (response.success === true) {
        setUid("");
        setName("");
        setEmail("");
        setSymbol("");
        setMode("");
        setTimeex("GTC");
        setType("");
        setQuantity(0);
        setLeverage(0);
        setMargin('')
        toast.dismiss();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(filteredData, '....')

  const records = () => {
    if (filteredData && filteredData.length > 0) {
      return filteredData.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr key={row.userId}>
            <th scope="row">{serialNumber}</th>
            <td>{row?.userId}</td>
            <td>{row?.name}</td>
            <td>{row?.email}</td>
            <td>
              <input
                type="checkbox"
                onChange={(e) =>
                  handleCheckboxChange(row.userId, e.target.checked)
                }
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="5" className="text-center">No data found</td>
        </tr>
      );
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  return (
    <>
      <div className="row mt-4 form user-form m-auto">
        <div className="heading text-center mb-3">
          <h3>Group Trade</h3>
        </div>
        <div className="col-md-12">
          <form className="row m-auto">
            <div className="col-md-6">
              <div className="form-group">
                <label className="">Symbol</label>
                <input
                  type="text"
                  className="form-control"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                />
                {latestPrice && (
                  <div className="mt-2 latest_price">Latest Price: {latestPrice}</div>
                )}
              </div>
              <div className="form-group">
                <label className="">Mode</label>
                <select
                  className="form-control input form-select"
                  aria-label="Default select example"
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                >
                  <option>mode</option>
                  <option value={"BUY"}>Buy</option>
                  <option value={"SELL"}>Sell</option>
                </select>
              </div>

              <div className="form-group">
                <label className="">Margin Type</label>
                <select
                  className="form-control input form-select"
                  aria-label="Default select example"
                  value={margin}
                  onChange={(e) => setMargin(e.target.value)}
                >
                  <option>Type</option>
                  <option value={"ISOLATED"}>ISOLATED</option>
                  <option value={"CROSSED"}>CROSSED</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="">Type</label>
                <select
                  className="form-control input form-select"
                  aria-label="Default select example"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>Type</option>
                  <option value={"MARKET"}>Market</option>
                  <option value={"LIMIT"}>Limit</option>
                </select>
              </div>
              <div className="form-group">
                <label className="">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  min="0"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <label className="">Leverage</label>
                <input
                  type="number"
                  className="form-control"
                  min="0"
                  value={leverage}
                  onChange={(e) => setLeverage(e.target.value)}
                />
                {type === "LIMIT" && (
                  <>
                    <label className="">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="row mt-3 text-center">
          <div className="col-12">
            {loading ? (
              <ColorRing
                height={65}
                width={65}
                colors={["#08d1d1", "#08d1d1", "#08d1d1", "#08d1d1", "#08d1d1"]}
                ariaLabel="loading"
              />
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handledashboards}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <input
            type="text"
            className="form-control"
            placeholder="Search by User ID"
            value={searchType}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>{records()}</tbody>
          </table>
          <Pagination
            current={currentPage}
            total={(count?.count)}
            pageSize={10}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </>
  );
};

export default Grouptrade;
