import React, { useState } from "react";

import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import logo from "../Assets/logo.png";
// import businessman from "../Assets/businessman.jpg";

const Forget = () => {
  const { state } = useLocation();
  const [email, setEmail] = useState(state?.email || "");
  const [password, setPassword] = useState();
  const [otp, setOtp] = useState();
  const [passwordconfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!email || !password || !otp || !passwordconfirm) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);

    try {
      const ForgetData = {
        email: email,
        password: password,
        otp: otp,
        confirmpassword: passwordconfirm,
      };
      let response = await _fetch(
        `${base_url}api/trader/forgotePassword`,
        "POST",
        ForgetData,
        {}
      );

      // console.log("response", response);
      if (response.success === true) {
        toast.dismiss();
        toast.success(response.message);
        navigate("/");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="conatiner-fluid">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="sign-page ios ">
            <div className="heading text-center ">
              <NavLink to="/">
                <img className="welcome" src={logo} alt="" />
              </NavLink>
              <h3 className="H_3 email">Forget</h3>

              {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email{" "}
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  className="form-control"
                  aria-describedby="emailHelp"
                  readOnly
                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Otp{" "}
                </label>
                <input
                  type="text"
                  id="name"
                  onChange={(e) => setOtp(e.target.value)}
                  autoComplete="off"
                  className="form-control"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye"></i>
                    ) : (
                      <i className="bi bi-eye-slash"></i>
                    )}
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Confirm Password
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="sponser"
                    value={passwordconfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    className="form-control"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <i className="bi bi-eye"></i>
                    ) : (
                      <i className="bi bi-eye-slash"></i>
                    )}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary m-auto d-block"
                onClick={handleSignUp}
              >
                {loading ? (
                  <ColorRing
                    height={20}
                    width={20}
                    color="#fff"
                    ariaLabel="loading"
                  />
                ) : (
                  "Submit"
                )}
              </button>
              <div>
                <div className="small fw-bold mt-2 pt-1 mb-0 text-center">
                  Have an account?{" "}
                  <NavLink to="/" className="link-danger">
                    Login
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forget;
